import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 190,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
)

function SimpleSelect({ options, update, value, label, name }) {
  // console.log(`SimpleSelect: '${value}'`)
  const classes = useStyles()

  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    // console.log(`handleCh '${event.target.value}'`)
    update(event.target.value)
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        inputProps={{
          name,
          id: name,
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} {...option} />
        ))}
      </Select>
    </FormControl>
  )
}
export default SimpleSelect
