import { useMutation, useQuery } from "@apollo/client"
import { createStyles, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Paper, Radio, RadioGroup, Theme } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { RouteChildrenProps } from "react-router"
import { adminUpsertGameInstance, adminUpsertGameInstanceVariables } from "../../../__generated__/adminUpsertGameInstance"
import { CentralGameInstancesQuery } from "../../../__generated__/CentralGameInstancesQuery"
import { customCopyHtml, gameTextKeys } from "../../../common/game-text"
import { fromGlobalId } from "../../../common/global-ids"
import { emptyObject } from "../../../common/misc-utils"
import Code from "../../components/Code"
import CustomCopy from "../../components/CustomCopy"
import { CENTRAL_GAME_INSTANCES_QUERY } from "../../utils/central-queries"
import { getStringParam } from "../../utils/url-utils"
import InfoSection from "../components/InfoSection"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import SimpleSelect from "../components/SimpleSelect"
import { ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../queries"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
  }),
)

function CustomCopyPage({ location, history }: RouteChildrenProps) {
  const classes = useStyles()
  const centralGameInstancesQuery = useQuery<CentralGameInstancesQuery>(CENTRAL_GAME_INSTANCES_QUERY)
  const gameInstanceId = getStringParam("gameInstanceId", location.search) || ""
  const seasonId = getStringParam("seasonId", location.search) || ""
  const code = getStringParam("code", location.search) || ""
  const gameInstance = centralGameInstancesQuery.data?.gameInstances.edges.find(({ node }, i) =>
    gameInstanceId ? node.id === gameInstanceId : i === 0,
  )?.node
  const segment = centralGameInstancesQuery.data?.allSegments.find((seg) => {
    if (seasonId) {
      return seg.season.id === seasonId
    }
    if (gameInstanceId) {
      return seg.season.gameInstance.id === gameInstanceId
    }
    return false
  })
  const poolSettings = segment?.poolSettings as any
  const season = segment?.season
  const [mutate, mutationResult] = useMutation<adminUpsertGameInstance, adminUpsertGameInstanceVariables>(ADMIN_UPSERT_GAME_INSTANCE_MUTATION)
  const { register, handleSubmit, control } = useForm()
  // get gameInstance first:
  if (!gameInstanceId || !code) {
    const options = centralGameInstancesQuery.data?.gameInstances.edges.map(({ node }) => ({ value: node.id, children: node.uid }))
    function updateGi(id: string) {
      const newLocation = Object.assign({}, location, { search: `code=${encodeURIComponent(code)}&gameInstanceId=${encodeURIComponent(id)}` })
      // console.log(`newLocatio ${table}`, newLocation)
      return history.replace(newLocation)
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCode = (event.target as HTMLInputElement).value
      const newLocation = Object.assign({}, location, {
        search: `code=${encodeURIComponent(newCode)}&gameInstanceId=${encodeURIComponent(gameInstanceId)}`,
      })
      // console.log(`newLocatio ${table}`, newLocation)
      return history.replace(newLocation)
    }
    return (
      <Layout title={`Custom Copy - Explorer`}>
        {(gameInstance && (
          <form className={classes.paper} action="#">
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <Paper className={classes.paper}>
                  <SimpleSelect options={options} value={gameInstanceId} update={updateGi} label="Game Instance" name="gameInstanceId" />
                </Paper>
              </Grid>
              <Grid item={true} xs={12}>
                <Paper className={classes.paper}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Code Value</FormLabel>
                    <RadioGroup aria-label="code" name="code" value={code} onChange={handleChange}>
                      {gameTextKeys.map((kode) => (
                        <FormControlLabel key={kode} value={kode} control={<Radio />} label={kode} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )) || <LoadingView />}
      </Layout>
    )
  }
  if (!gameInstance) {
    return (
      <Layout title={`Custom Copy - '${code}'`}>
        <LoadingView />
      </Layout>
    )
  }
  // const currentValue = customCopyHtml(code, gameInstance, season, segment, poolSettings, false)
  const seasonCustomCopy = season?.customCopy || emptyObject
  const gameInstanceCustomCopy = gameInstance.customCopy || emptyObject
  const currentValue = seasonCustomCopy[code] || gameInstanceCustomCopy[code] || ""
  const currentTargetTable = seasonCustomCopy[code] ? "Season" : "GameInstance"
  const onSubmit = async (data) => {
    const tableName = data.targetTable
    const gid = fromGlobalId((tableName === "Season" && season?.id) || gameInstance.id)
    const id = parseInt(gid.id)
    const prevCustomCopy = (tableName === "Season" && seasonCustomCopy) || gameInstanceCustomCopy
    const customCopy = Object.assign({}, prevCustomCopy, { [code]: data.customCopy })
    if (!customCopy[code]) {
      delete customCopy[code]
    }
    const newParams = { customCopy: JSON.stringify(customCopy), id }
    // console.dir(newParams);
    await mutate({
      variables: {
        mapping: {
          [tableName]: newParams,
        },
      },
    })
    // refetch original query
    await centralGameInstancesQuery.refetch()
  }
  return (
    <Layout title={`Custom Copy - '${gameInstance?.uid}' - '${code}'`}>
      <InfoSection id="1" heading="Code">
        <p>Unique string (no spaces or special characters) describing where this copy lives:</p>
        <Code>{code}</Code>
      </InfoSection>
      <InfoSection id="2" heading="Default Value">
        <p>
          Default value for <code>{code}</code> for this Game's Season.
        </p>
        <p>This is based on any logic specific to season, game, and settings:</p>
        <Code>{customCopyHtml(code, gameInstance, season, segment, poolSettings as any, false, undefined, undefined, true)}</Code>
        <p>
          Example:
          <br />
          <CustomCopy
            code={code}
            gameInstance={gameInstance}
            season={season}
            segment={segment}
            poolSettings={poolSettings}
            bypassCustomCopy={true}
            removeEditLink={true}
          />
        </p>
      </InfoSection>
      <InfoSection id="3" heading="Custom Value">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Custom Value Placement</FormLabel>
            <Controller
              as={
                <RadioGroup aria-label="Custom Copy Destination">
                  <FormControlLabel value="GameInstance" control={<Radio />} label="Game (every season)" />
                  <FormControlLabel value="Season" control={<Radio />} label="Season (changes each season)" />
                </RadioGroup>
              }
              defaultValue={currentTargetTable}
              name="targetTable"
              control={control}
            />
          </FormControl>
          <TextField
            multiline={true}
            inputRef={register}
            label="Custom Value"
            rowsMax={8}
            fullWidth={true}
            name="customCopy"
            defaultValue={currentValue}
            autoComplete="off"
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" color="primary" disabled={mutationResult.loading} type="submit">
            {mutationResult.loading ? `Saving...` : `Save`}
          </Button>
        </form>
      </InfoSection>
    </Layout>
  )
}

export default CustomCopyPage
