import { Accordion, AccordionDetails, Typography, AccordionSummary } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons"
import React from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expansionArea: {
      display: "block",
    },
  }),
)

function InfoSection({ id, heading, children }) {
  const classes = useStyles()
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
          <Typography>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionArea}>{children}</AccordionDetails>
      </Accordion>
      <br />
    </>
  )
}
export default InfoSection
